import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchWarehouses() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.company.Controller.findAllWarehouses())
        .then(response => resolve({
          data: response.data.body,
        }))
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Items
  async fetchItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findItemById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllItemsByBatchId(batchId, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findAllItemsByBatchId(batchId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllItemsByCompanyIdAndStatus(companyId, status, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findAllItemsByCompanyIdAndStatus(companyId, status, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchReceivePageViewByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findReceivePageViewByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchFbaShipmentBatchParentItemByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findFbaShipmentBatchParentItemByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getBySearchTerm(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.getBySearchTerm(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getBySearchList(filter, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.getBySearchList(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // Add ship CSV uploaded data
  async addShipCsvUpload(data) {
    const { params } = data
    const list = data.data
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.csvUpload(params), list)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createItem(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.createItem(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async createMissingItems(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.createMissingItems(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async changeItemQuantity(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.changeItemQuantity(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateItem(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateItem(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async updateDraftItem(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateDraftItem(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async updateItemStatus(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateItemStatus(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateMskuFnsku(data, itemId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateMskuFnsku(itemId), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async moveItems(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.moveItems(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async receiveItems(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.receiveItems(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async receiveItemsByIdList(data, planId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.receiveItemsByIdList(planId), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async receiveItemsDamaged(batchId, id, count, packageScanId, problem) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.receiveItemsDamaged(batchId, id, count, packageScanId), problem)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.ship.Controller.deleteItemById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteManyItemsById(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.deleteManyItemsById(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Client Shipment Batches
  async fetchBatchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findBatchById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async amazonInventorySummary(id) {
    const response = await axios.get(endpoints.ship.Controller.amazonInventorySummary(id))
    return response.data.body
  },

  async getShipmentPlanStats(batchId) {
    const response = await axios.get(endpoints.ship.Controller.getShipmentPlanStats(batchId))
    return response.data.body
  },

  async inboundItemUpdate(data) {
    await axios.put(endpoints.ship.Controller.inboundItemUpdate(), data)
  },

  async resolveItemStatus(id, status) {
    await axios.put(endpoints.ship.Controller.resolveItemStatus(id, status))
  },

  async fetchAllBatches(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findAllBatches(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllBatchesByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findAllBatchesByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllBatchesByQueryWarehouse(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findAllBatchesByQueryWarehouse(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllBatchesForCurrentCompanyByQuery(params, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findAllBatchesForCurrentCompanyByQuery(params, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createBatch(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.createBatch(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async updateBatch(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateBatch(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async submitDraftBatch(data) {
    const result = await axios.put(endpoints.ship.Controller.submitDraftBatch(data))
    return result
  },

  async completeBatchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.completeBatchById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteBatchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.ship.Controller.deleteBatchById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Client Shipment Bundles
  async fetchBundleById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findBundleById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchItemsByBundleId(bundleId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findItemsByBundleId(bundleId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchBundlesByQuery(params, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findBundlesByQuery(params, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createBundle(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.createBundle(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async updateBundle(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.Controller.updateBundle(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteBundleById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.ship.Controller.deleteBundleById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // Tracking Services
  async createTracking(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.createTracking(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchTrackingsByItemId(params, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.Controller.findTrackingsByItemId(params, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async deleteTrackingById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.ship.Controller.deleteTrackingById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchItemsByProductId(data, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findItemsByProductId(pageable), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async findAmazonRanks(data, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.ship.Controller.findAmazonRanks(pageable), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async findItemsByTrackingId(companyId, trackingId) {
    const result = await axios.get(endpoints.ship.Controller.findItemsByTrackingId(companyId, trackingId))
    return result.data.body
  },
  async getListToDownload(data, pageable) {
    const result = await axios.post(endpoints.ship.Controller.getListToDownload(pageable), data)
    return result.data
  },
  async upsertItem(data) {
    const result = await axios.put(endpoints.ship.Controller.upsertItem(), data)
    return result.data
  },
  async queryUncompleted(data, pageable) {
    const result = await axios.post(endpoints.ship.Controller.queryUncompleted(pageable), data)
    return result.data.body
  },
  async updateNotes(data) {
    const result = await axios.put(endpoints.ship.Controller.updateNotes(), data)
    return result.data.body
  },
}
